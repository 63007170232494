































































































import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { Observer } from 'mobx-vue'
import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { PostListByCategoryViewModel } from '@/modules/community/home/viewmodels/post-list-by-category-viewmodel'

@Observer
@Component({
  components: {
    'most-active-user-section': () => import('@/modules/common/components/section/most-active-user-section.vue'),
    'post-list': () => import('@/modules/community/home/components/review-category-posts/post-list.vue'),
    'post-grid': () => import('@/modules/community/common/components/post-grid.vue'),
  },
})
export default class extends Vue {
  createPostController = createPostController
  @Provide() vm = new PostListByCategoryViewModel()

  @Watch('$route.params.id', { immediate: true }) idChange(val) {
    this.vm.init(val)
  }

  loadMore(entry) {
    if (this.vm.reviewCanLoadMore) {
      if (entry.isIntersecting) this.vm.loadMore()
    }
  }

  destroyed() {
    this.vm.destroy()
  }
}

import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { ReviewCategory } from '@/models/post-model'
import { apiService } from '@/services/api-services'
import { PostStore } from '@/stores/post-store'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { Subject } from 'rxjs'

export class PostListByCategoryViewModel {
  @observable categoryId?: string = ''
  @observable category?: ReviewCategory = undefined
  @observable reviews: PostStore[] = []
  @observable reviewFetching = false
  @observable filter = { _start: 0, _limit: 10, type: 'review', _sort: 'topScore:desc' }
  @observable total = 0
  @observable loadMoreState = { _start: 0, _limit: 10 }
  @observable loadingMore = false
  @observable displayType: 'list' | 'grid' = 'list'
  @observable tabs = ['TOP', 'Latest']
  @observable tab = this.tabs[0]

  private _disposers: IReactionDisposer[]
  private _unsubcrible = new Subject()

  constructor() {
    this._disposers = [
      reaction(
        () => this.filter,
        (filter) => {
          if (filter) {
            this.fetchReviews()
            this.loadMoreState = { _limit: 10, _start: 0 }
          }
        }
      ),
    ]
  }

  destroy() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
    this.reviews.forEach((postStore) => postStore.destroy())
    this._disposers.forEach((d) => d())
  }

  @action.bound changeFilter(filter) {
    this.filter = filter
  }

  @action.bound changeDisplayType(value) {
    this.displayType = value
  }
  
  onTabChange(tab: string) {
    this.tab = tab
    if (tab === 'TOP') {
      const filter = { ...this.filter, _start: 0, _limit: 10, _sort: 'topScore:desc' }
      this.changeFilter(filter)
    } else if (tab === 'Latest') {
      const filter = { ...this.filter, _start: 0, _limit: 10, _sort: 'createdAt:desc' }
      this.changeFilter(filter)
    }
  }

  @asyncAction *init(categoryId: string) {
    this.categoryId = categoryId
    this.changeFilter({ ...this.filter, reviewCategories: categoryId })
    this.fetchCategory()
    this.fetchReviews()
  }

  @asyncAction *fetchCategory() {
    try {
      const res = yield apiService.reviewCategories.find({ id: this.categoryId })
      if (res && res[0]) {
        this.category = res[0]
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *fetchReviews() {
    try {
      this.reviewFetching = true
      const reviews = yield apiService.posts.fetchPosts(this.filter)
      if (reviews) {
        this.reviews = reviews.map((item) => new PostStore(item))
        this.total = reviews[0]?.total || 0
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.reviewFetching = false
    }
  }

  @asyncAction *loadMore() {
    try {
      this.loadingMore = true
      this.loadMoreState = { ...this.filter, _start: this.loadMoreState._start + this.loadMoreState._limit }
      const postMore = yield apiService.posts.fetchPosts({ ...this.loadMoreState })
      const newPost = postMore.map((post) => new PostStore(post))
      const currentPost = this.reviews
      this.reviews = [...currentPost, ...newPost]
    } catch (error) {
    } finally {
      this.loadingMore = false
    }
  }

  @computed get reviewCanLoadMore() {
    if (this.reviews.length < this.total) return true
    else return false
  }
}
